.about-info{
    padding-top: 80px; 
    padding-bottom: 80px;
}
.about-info img{
    width: 100%;
    // max-height: 550px;
}
.about-info .about-information{
    display:flex;
    justify-content: center; 
    align-items: center;
}