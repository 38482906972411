/*------- 5. Section title style  -------*/

.section-title {
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 30px;
    }
  }
  h2 {
    // font-size: 30px;
    // font-weight: 600;
    margin: 0 0 0;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    @media #{$xs-layout} {
      font-size: 24px !important;
      line-height: 30px;
      &.mb-50 {
        margin-bottom: 30px;
      }
    }
    &::before {
      position: absolute;
      content: "";
      left: -100px;
      background-color: #1d1b1c;
      width: 80px;
      height: 1px;
      top: 17px;
      @media #{$xs-layout} {
        left: -40px;
        width: 30px;
      }
    }
    &::after {
      position: absolute;
      content: "";
      right: -100px;
      background-color: #1d1b1c;
      width: 80px;
      height: 1px;
      top: 17px;
      @media #{$xs-layout} {
        right: -40px;
        width: 30px;
      }
    }
  }
  &.mb-55 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
  }

  &.no-border {
    h2 {
      font-size: 36px;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.section-title-2 {
  h2 {
    font-size: 30px;
    color: #000000;
    font-weight: 600;
    margin: 0 0 15px;
    padding: 0 0 15px;
    position: relative;
    display: inline-block;
    &:before {
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
      width: 80px;
      height: 2px;
      background-color: #000000;
    }
  }
  p {
    color: #333;
    margin: 0;
  }
  &.mb-60 {
    @media #{$xs-layout} {
      margin-bottom: 30px;
    }
  }
}

.section-title-3 {
  h4 {
    font-weight: 500;
    color: #000000;
    font-size: 24px;
  }
  &.mb-40 {
    @media #{$xs-layout} {
      margin-bottom: 20px;
    }
  }
}

.section-border {
  margin-right: 94px;
  overflow: hidden;
  position: relative;
  @media #{$xs-layout} {
    margin-right: 0px;
  }
  &::before {
    background-color: #d2d2d2;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 11px;
    transition: all 0.4s ease 0s;
    width: 100%;
    z-index: 1;
    @media #{$xs-layout} {
      display: none;
    }
  }
}

.section-title-4 {
  position: relative;
  z-index: 999;
  h3 {
    font-weight: 600;
    color: #242424;
    font-size: 20px;
    letter-spacing: -0.25px;
    margin: 0;
    line-height: 1;
    padding-right: 45px;
    @media #{$xs-layout} {
      padding-right: 0px;
    }
  }
}

.section-title-5 {
  h2 {
    color: #010101;
    font-size: 48px;
    margin: 0;
    line-height: 38px;
    @media #{$md-layout} {
      font-size: 40px;
    }
    @media #{$xs-layout} {
      font-size: 30px;
      line-height: 27px;
    }
  }
  @media #{$xs-layout} {
    &.mb-60 {
      margin-bottom: 30px;
    }
  }
}
