/* WatchInfo.scss */
.watch-info-container {
    background-color: #f5f5f5; /* Light gray background color */
    padding-top: 20px; /* Add some padding for spacing */
    padding-bottom: 20px;
    overflow: hidden;
  }
  
  .watch-info {
    display: flex;
    justify-content: space-around;
  }
  
  .info-section {
    text-align: center;
    padding: 20px;
    // border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    // background: black;
    color: white;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: transform 0.3s ease-in-out, height 0.3s ease-in-out; /* Adjusted transition for height */
  }
  
  .info-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: #ebe9e9;
    z-index: -1;
    transition: transform 0.3s ease-in-out;
  }
  
  .info-section h2 {
    font-family: 'Arial', sans-serif; /* Change the font family */
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8); /* Add glowing effect to text */
  }
  
  .info-section p {
    font-size: 16px;
    color: #696969;
    margin: 0;
    line-height: 1.5;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5); /* Add glowing effect to text */
  }
  
  // .info-section:hover {
  //   transform: scale(1.05); /* Increase size on hover */
  //   height: 150%; /* Increase height on hover */
  // }
  
  .info-section:hover::before {
    transform: scale(1.1); /* Increase size on hover */
  }
  .watch-info .info-section img{
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  .info-section-img img{
    width: 100%;
  }